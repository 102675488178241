import React from 'react';

const LandingPage = () => {
  return (
    <div className="bg-gray-900 text-white min-h-screen">
      {/* Hero Section */}
      <header className="bg-black py-16 text-center">
        <h1 className="text-5xl font-bold">Welcome to 10xDev</h1>
        <p className="mt-4 text-lg">Your partner in development excellence</p>
        <a
          href="#services"
          className="mt-8 inline-block bg-yellow-500 text-black px-8 py-4 rounded-full font-bold text-lg hover:bg-yellow-400 transition"
        >
          Explore Our Services
        </a>
      </header>

      {/* Features Section */}
      <section id="services" className="py-20">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold">Why Choose 10xDev?</h2>
          <p className="text-lg mt-4">We provide expert development services to help your business succeed.</p>

          <div className="mt-10 grid grid-cols-1 md:grid-cols-3 gap-10">
            <div className="p-6 bg-gray-800 rounded-lg">
              <h3 className="text-2xl font-bold">Custom Web Development</h3>
              <p className="mt-4">Bespoke solutions tailored to your business needs.</p>
            </div>
            <div className="p-6 bg-gray-800 rounded-lg">
              <h3 className="text-2xl font-bold">Cloud Infrastructure</h3>
              <p className="mt-4">Modernize your cloud services and optimize performance.</p>
            </div>
            <div className="p-6 bg-gray-800 rounded-lg">
              <h3 className="text-2xl font-bold">Enterprise Consulting</h3>
              <p className="mt-4">Consulting services to drive digital transformation.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="bg-yellow-500 py-20">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold text-black">Get in Touch with Us</h2>
          <p className="mt-4 text-black text-lg">We are ready to help you build the future of your business.</p>
          <a
            href="/contact"
            className="mt-6 inline-block bg-black text-yellow-500 px-8 py-4 rounded-full font-bold text-lg hover:bg-gray-800 transition"
          >
            Contact Us
          </a>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-black py-6">
        <div className="container mx-auto text-center">
          <p>© 2024 10xDev. All Rights Reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
